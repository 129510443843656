<template>
  <div class="d-flex d-col h100 vh100">
    <TopToolbar />
    <div class="d-flex grow">
      <Sidebar />
      <div class="grow col page_container gap-50">
        <div class="col pt-50 center">
          <h1 class="text-center">Software Hub</h1>
          <span class="weight-300 opacity-75 center text-center">
            Experience the most secure Video Conferencing & Document Transfer App wherever you go on any device!
          </span>
        </div>
        <div class="col gap-10 center">
          <h2 class="weight-600 green--text mb-0">
            Our Products
          </h2>
        </div>
        <hr />
        <div class="row center gap-20 pb-50">
          <div
            v-for="product in downloads[0].downloads"
            :key="product.idx"
            class="product"
          >
            <div class="icon center">
              <s-icon width="50" color="white">{{
                product.icon.slice(0, -4)
              }}</s-icon>
            </div>
            <span class="text-center font-14 title">{{ product.title }}</span>
            <a target="blank" :href="product.url" class="downloadBtn">
              Download
            </a>
            <div class="text-center font-12 pt-5 version">
              <span class="opacity-75">Version: </span>
              <span>{{ product.version }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
    
    <script>
import Sidebar from "@/components/Toolbars/Sidebar";
import TopToolbar from "@/components/Toolbars/TopToolbar.vue";
import Footer from "@/components/Toolbars/Footer.vue";
import { mapState } from "vuex";

export default {
  name: "Downloas",
  components: {
    Sidebar,
    TopToolbar,
    Footer,
  },
  computed: {
    ...mapState("user", ["downloads"]),
  },
  mounted() {
    this.$store.dispatch("user/getDownloads");
  },
};
</script>
  
<style scoped>
span {
  max-width: 500px;
}

.logo {
  width: 100%;
  max-width: 342px;
  padding: 20px;
  margin-top: 8px;
  box-sizing: border-box;
}

.product {
  height: 240px;
  width: 170px;
  background-color: #141a25;
  border-radius: 3px;
  padding: 14px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.product:hover {
  filter: brightness(1.2);
}

.product:hover .downloadBtn {
  background-color: #409875;
}

.downloadBtn {
  outline: 1px solid #394a58;
  padding: 7px 14px;
  border-radius: 3px;
  font-size: 14px;
  transition: all 0.3s ease;
  color: #fff;
}

.downloadBtn:hover {
  background-color: #409875;
}

.icon {
  height: 100px;
  width: 100%;
}

.title {
  height: 50px;
}



@media only screen and (max-width: 576px) {
  .page_container {
    max-height: calc(100vh - 60px);
  }
}
</style>
    
    